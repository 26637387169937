<template>
  <StudentForm v-if="!isForm" @user="userInfo" />
  <div v-else class="page-section">
    <div class="container">
      <h2 class="text-center mb-2">Payment</h2>
      <p class="text-center mb-4">
        Please complete your payment information and pick up your order from the school after successful payment.
      </p>

      <div class="card">
        <div class="card-header bg-light">
          <h5 class="my-0" style="font-weight: 600;">Personal Information</h5>
        </div>
        <div class="card-body">
          <h6 class="mb-2" style="font-size: 15px; font-weight: 500;">
            <i class="material-icons icon-18pt mr-8pt"> school </i>{{ user.first_name }}
            {{ user.last_name }}
          </h6>

          <h6 style="font-size: 15px; font-weight: 500;" class="mb-2">
            <i class="material-icons icon-18pt mr-8pt">email</i>{{ user.email }}
          </h6>
          <h6 style="font-size: 15px; font-weight: 500;" class="mb-2">
            <i class="material-icons icon-18pt mr-8pt">phone</i>{{ user.phone }}
          </h6>
          <h6 style="font-size: 15px; font-weight: 500;">
            <i class="material-icons icon-18pt mr-8pt">location_on</i>{{ get(user, 'address') }},
            {{ get(user, 'city') }},

            {{ get(user, 'state') }}
            {{ get(user, 'zipcode') }},
            {{ get(user, 'country') }}
          </h6>
        </div>
      </div>
      <b-form class="col-md-12 px-0 page-section pt-0 pb-5 mb-5" @submit.prevent="pay">
        <div v-if="getCartArr.length">
          <product-checkout-card :cart-arr="getCartArr" />
        </div>

        <div class="card">
          <div class="card-header bg-light">
            <h5 class="my-0">Payment Information</h5>
          </div>

          <div class="card-body">
            <b-skeleton-wrapper :loading="isPaymentDetailsLoading">
              <template #loading>
                <p class="lead text-white-50">
                  <b-skeleton width="70%"></b-skeleton>
                </p>
                <p class="lead text-white-50">
                  <b-skeleton width="60%"></b-skeleton>
                </p>
                <p class="lead text-white-50">
                  <b-skeleton width="50%"></b-skeleton>
                </p>
              </template>
            </b-skeleton-wrapper>
            <stripe-element-payment
              v-if="!paymentMethod && showPaymentDetails"
              ref="paymentRef"
              :pk="pk"
              :testMode="testMode"
              :elements-options="elementsOptions"
              :confirm-params="confirmParams"
              @loading="value => (isPayLoading = value)"
              @element-ready="() => (isPaymentDetailsLoading = false)"
            />
          </div>
        </div>
        <b-btn
          :disabled="isPayLoading || isPaymentDetailsLoading"
          variant="primary"
          style="width: 150px"
          type="submit"
          class="btn-normal float-right"
        >
          <i v-if="isPayLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>Place order</span>
        </b-btn>
      </b-form>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import Page from '@/components/Page.vue';
import StudentForm from './StudentForm.vue';
import { mapActions, mapGetters } from 'vuex';
import ProductCheckoutCard from './ProductCheckoutCard.vue';
import { StripeElementPayment } from '@vue-stripe/vue-stripe';

export default {
  name: 'ProductsPayment',
  components: { ProductCheckoutCard, StripeElementPayment, StudentForm },
  extends: Page,
  data() {
    return {
      title: 'Payment',
      windowWidth: window.innerWidth,
      firstLoad: true,
      isLoading: false,
      perPage: 14,
      currentPage: 1,
      totalAdvisors: 0,
      pageFromCount: 0,
      pageToCount: 0,
      allAdvisors: [],
      filters: {
        programs_id: null,
        background: null,
        uni_id: null,
      },
      pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      testMode: process.env.VUE_APP_TEST_MODE === 'true',
      isPayLoading: false,
      isPaymentDetailsLoading: false,
      areProductsLoading: false,
      quantity: 0,
      product: {},
      showProductDetail: false,
      showCartModal: false,
      elementsOptions: {
        appearance: { theme: 'stripe' }, // appearance options
        // 'clientSecret' being added below.
      },
      intentDraftId: null,
      confirmParams: {},
      paymentMethod: null,
      isPaymentMethodLoading: false,
      showPaymentDetails: false,
      isForm: false,
      user: {},
    };
  },

  computed: {
    ...mapGetters('productsStore', ['getCartArr']),
    ...mapGetters('school', ['getCurrentSchool']),
    ...mapGetters('auth', ['getLoggedInUserDashboard', 'getLoggedInUser']),

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },

  methods: {
    get,
    ...mapActions('settings', ['setSettings']),
    ...mapActions('productsStore', ['emptyCart']),
    ...mapActions('student', ['getPublicProducts']),
    ...mapActions('payoutAcc', ['createProductPaymnetIntent', 'getDefaultPaymentMethod']),

    userInfo(data) {
      this.user = data;
      this.isForm = true;
      this.createPaymnetIntent();
    },

    openDetailModal(data) {
      this.showProductDetail = true;
      this.product = data;
    },

    hideDetailModal() {
      this.showProductDetail = false;
      this.product = {};
    },

    async pay() {
      this.$refs.paymentRef.submit();
      this.emptyCart();
    },
    async fetchPaymentMethod() {
      this.isPaymentMethodLoading = true;
      try {
        this.paymentMethod = get((await this.getDefaultPaymentMethod()).data, 'data');
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isPaymentMethodLoading = false;
    },
    async createPaymnetIntent() {
      if (!this.paymentMethod) {
        this.isPaymentDetailsLoading = true;
      }

      try {
        const products = [];
        this.getCartArr.map(product =>
          products.push({
            id: product.id,
            quantity: product.quantity,
            size: product.size,
          })
        );
        const paymentIntent = await this.createProductPaymnetIntent({
          products: this.getCartArr.length > 0 ? products : [],
          school: get(this.getCurrentSchool, 'id'),
          ...this.user,
        });

        if (paymentIntent.data.intent) {
          this.elementsOptions['clientSecret'] = paymentIntent.data.intent;

          this.confirmParams['return_url'] = `${window.location.origin}/store/?checkoutSuccess=1`;
          this.showPaymentDetails = true;
        }
      } catch (e) {
        await this.makeToast({ variant: 'danger', msg: 'Some error has occured. Try again later.' });
      }
    },
  },
  async mounted() {},
  async created() {
    this.setSettings({ layout: { layout: 'fixed' } });
  },
};
</script>
