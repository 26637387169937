<template>
  <b-modal ref="orderSuccessModal" hide-footer hide-header centered @hide="hideModal" size="md">
    <div class="d-flex flex-column align-items-center justify-content-center">
      <i class="fas fa-check-circle text-success my-3" style="font-size: 40px;"></i>

      <strong style="font-size: 20px;">
        Thank you
      </strong>

      <p class="my-2">
        Your order has been placed successfully. You will receive an email for your order confirmation shortly.
      </p>

      <b-btn variant="primary" style="min-width: 150px" @click.prevent="continueToDetails" class="btn-normal my-3">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Continue Shopping</span>
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    showModal: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.orderSuccessModal.show();
      }
    },
  },

  methods: {
    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.orderSuccessModal.hide();
      this.hideModal();
    },

    continueToDetails() {
      this.hideModalManual();
    },
  },
};
</script>

<style></style>
