<template>
  <div style="margin-top: -18px" :class="isMobSmallScreen ? '' : 'container'">
    <div class="bg-primary page-section employer-header-bg border-bottom-white mb-10pt img-fluid"
      :style="`background-image: url('${get(getCurrentSchool, 'profile_bg_img_url')}');`">
      <div class="container page__container mb-0 d-flex align-items-center justify-content-center h-100"
        :style="`background:(rgba(${rgbDarkColor.r}, ${rgbDarkColor.g}, ${rgbDarkColor.b}, 0.95) !important;`">
        <div class="text-center mb-1" v-if="get(getCurrentSchool, 'show_banner_status')">
          <h1 class="text-white mb-8pt employer-header-h1" style="">
            School Store
          </h1>
          <p class="measure-lead mx-auto text-white d-lg-block employer-header-text mb-0">
            Our online store offers a variety of products to support academic success and school pride.
          </p>
        </div>
      </div>
    </div>
    <div v-if="!continuePay" class="mt-4">
      <div class="ml-auto" style="width: 250px;">
        <v-select class="form-control v-select-custom" label="text" v-model="selectedCategory"
          :reduce="category => category.value" placeholder="Filter by product category" :options="categoryOptions"
          @input="onCategoryFilter" clearable>
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
      </div>
      <div class="d-flex align-items-center my-3">
        <div class="w-100 mr-2">
          <page-separator :title="!isLoading
              ? `showing ${totalAdvisors ? `${pageFromCount}-${pageToCount}` : 0} of ${totalAdvisors} Products`
              : ''
            " />
        </div>
      </div>


      <div class="page-section d-flex justify-content-center" v-if="!allProducts.length && !isLoading">
        <div class="card card-block card-stretch card-height col-md-8">
          <div class="card-body text-center">
            <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
            <h4>No Products</h4>

            <p class="text-muted">Couldn't find any Products.</p>
          </div>
        </div>
      </div>

      <b-skeleton-wrapper :loading="isLoading" v-else>
        <template #loading>
          <div class="row card-group-row mb-lg-12pt home-card">
            <div class="col-md-6 col-lg-6 card-group-row__col" v-for="item in [1, 2]" :key="item">
              <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                <div class="card-header d-flex align-items-center">
                  <a href="#" @click.prevent class="card-title flex"> <b-skeleton width="100%"></b-skeleton></a>
                </div>
                <div class="card-body d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <div class="flex">
                      <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                        <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                          <a href="#" @click.prevent
                            class="avatar avatar-xl overlay js-overlay overlay--primary rounded-circle p-relative o-hidden mb-16pt align-self-center"
                            style="border: 1px solid #dfe2e6">
                            <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                          </a>
                        </div>
                        <div class="flex">
                          <div class="mb-0" style="text-transform: none">
                            <b-skeleton width="100%"></b-skeleton>
                            <b-skeleton width="55%"></b-skeleton>
                            <b-skeleton width="70%"></b-skeleton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="row">
          <div v-for="pro in allProducts" :key="pro.id" class="col-lg-6 col-md-6 col-sm-12 clickable-item">
            <router-link :to="`/store/${pro.slug}`">
              <div class="card card-sm card--elevated">
                <span class="js-image d-flex justify-content-center">
                  <img :src="pro.product_url" alt="cna"
                    style="height: 350px; width: 100%; object-fit: contain; border-top-right-radius: 0.5rem; border-top-left-radius: 0.5rem;" />
                </span>

                <div class="card-body">
                  <div class="card-title mb-2">{{ pro.product_title }}</div>
                  <div class="d-flex align-items-center">
                    <div>{{ $n(pro.price, 'currency', 'en-US') }}</div>
                    <b-btn class="ml-auto" variant="primary" style="height: 40px; min-width: 100px;"
                      @click.prevent="openDetailModal(pro)">
                      <span>Add To Cart</span>
                    </b-btn>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </b-skeleton-wrapper>
    </div>

    <ProductsPayment v-else :cart-arr="cartArr" />

    <div class="my-3" v-if="totalAdvisors > allProducts.length">
      <pagination v-model="currentPage" :total-rows="totalAdvisors" :per-page="perPage" @change="onPageChange" />
    </div>

    <OrderSuccessModal :show-modal="showSuccessModal" />

    <product-detail-modal :show-modal="showProductDetail" :product="product" @close="hideDetailModal" />
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import ProductsPayment from './ProductsPayment.vue';
import { hexToRgb } from '@/common/utils';
import OrderSuccessModal from './OrderSuccessModal.vue';
import ProductDetailModal from './ProductDetailModal.vue';
import vSelect from 'vue-select';

export default {
  name: 'AdvisorList',
  components: { PageSeparator, Pagination, ProductsPayment, OrderSuccessModal, ProductDetailModal, vSelect },
  extends: Page,

  data() {
    return {
      title: 'Store',
      windowWidth: window.innerWidth,
      firstLoad: true,
      isLoading: false,
      perPage: 14,
      currentPage: 1,
      totalAdvisors: 0,
      pageFromCount: 0,
      pageToCount: 0,
      allProducts: [],
      filters: {
        programs_id: null,
        background: null,
        uni_id: null,
      },
      quantity: 0,
      product: {},
      showProductDetail: false,
      showSuccessModal: false,
      cartArr: [],
      continuePay: false,
      selectedCategory: null,
      categoryOptions: [
        { text: 'Apparel', value: 'apparel' },
        { text: 'Accessories', value: 'accessories' },
        { text: 'Classroom Supplies', value: 'classroom_supplies' },
        { text: 'Clinical/Lab Supplies', value: 'clinical_supplies' },
      ],
    };
  },

  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    ...mapGetters('auth', ['getLoggedInUserDashboard', 'getLoggedInUser']),
    ...mapGetters('advisors', ['getAdvisorFilters']),

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },

    rgbDarkColor() {
      return hexToRgb(get(this.getCurrentSchool, 'dark_color', '#214e6f'));
    },
  },

  watch: {
    getCurrentSchool: {
      immediate: true,
      deep: true,
      handler(value) {
        if (get(value, 'id')) {
          this.fetchProducts();
        }
      },
    },
  },

  methods: {
    ...mapActions('student', ['getPublicProducts']),
    ...mapActions('settings', ['setSettings']),
    ...mapActions('productsStore', ['addToCart']),
    get,

    openSuccessModal() {
      this.showSuccessModal = true;
      this.$router.replace({
        name: '',
        query: {},
      });
    },

    openDetailModal(data) {
      this.showProductDetail = true;
      this.product = data;
    },

    hideDetailModal() {
      this.showProductDetail = false;
      this.product = {};
    },

    continueToDetails() {
      this.continuePay = true;
    },
    onCategoryFilter() {
      this.fetchProducts(1, {
        ...(this.selectedCategory && { category: this.selectedCategory })
      });
    },

    async fetchProducts(pageNum = 1, params = {}) {
      this.isLoading = true;

      const response = await this.getPublicProducts({
        school: get(this.getCurrentSchool, 'id'),
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...params,
      });

      this.allProducts = response.data.results;
      this.currentPage = pageNum;
      this.totalAdvisors = response.data.count;
      this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
      this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;

      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchProducts(pageNum);
    },
  },

  async mounted() {
    if (this.$route.query.checkoutSuccess == 1) {
      this.openSuccessModal();
    }
    this.setSettings({ layout: { layout: 'fixed' } });
  },
};
</script>
