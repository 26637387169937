<template>
  <div class="card">
    <div class="card-header bg-light">
      <h5 class="my-0">Product Details</h5>
    </div>
    <div class="card-body d-flex align-items-center">
      <div class="w-100">
        <b-table
          :fields="tableColumns"
          :items="cartArr"
          head-variant="light"
          class="table-nowrap"
          responsive
          hover
          no-local-sorting
        >
          <template #cell(product_title)="data">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" no-link slot="aside">
                <b-img
                  :src="data.item.product_url"
                  class="img-fluid"
                  width="40"
                  alt="Logo"
                  v-if="data.item.product_url"
                />
                <i class="fas fa-bag-shopping" v-else></i>
              </fmv-avatar>
              {{ data.item.product_title }}
            </div></template
          >

          <template #cell(price)="data"> {{ $n(data.item.price, 'currency', 'en-US') }}</template>
          <template #cell(quantity)="data">{{ $n(data.item.quantity) }}</template>
          <template #cell(amount)="data"> {{ $n(data.item.quantity * data.item.price, 'currency', 'en-US') }}</template>
        </b-table>
        <div class="mt-3 p-1">
          <div class="icon-align mb-2 d-flex justify-content-between" v-if="get(cartArr, 'length')">
            <b>Products:</b>
            <b>
              {{ !cartArr.length ? $n(0, 'currency', 'en-US') : $n(totalAmount, 'currency', 'en-US') }}
            </b>
          </div>

          <div class="icon-align mb-2 d-flex justify-content-between">
            <b class="mr-1">Online Service Fee:</b>
            <b>
              {{ !cartArr.length ? $n(0, 'currency', 'en-US') : $n(calcServiceFee, 'currency', 'en-US') }}
            </b>
          </div>

          <hr />

          <div class="d-flex justify-content-between">
            <b>Total Amount Due:</b>
            <b>{{ !cartArr.length ? $n(0, 'currency', 'en-US') : $n(calTotalFee, 'currency', 'en-US') }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { FmvAvatar } from 'fmv-avatar';
import { STD_REG_MIN_SERVICE_FEE } from '@/common/constants';

export default {
  name: 'ProductCheckoutCard',
  props: {
    cartArr: { type: Array, default: () => [] },
  },
  components: {
    FmvAvatar,
  },
  computed: {
    tableColumns() {
      return [
        { key: 'product_title', label: 'Product', tdClass: 'text-left', thClass: 'text-left p-1' },
        { key: 'price', label: 'Price' },
        { key: 'quantity', label: 'Quantity' },
        { key: 'size', label: 'Size', tdClass: 'text-center', thClass: 'text-center' },
        { key: 'amount', label: 'Amount', tdClass: 'text-right p-1', thClass: 'text-right p-1' },
      ];
    },

    total() {
      return this.cartArr.reduce((total, item) => item.price * item.quantity + total, 0);
    },

    totalAmount() {
      return this.cartArr.reduce((total, item) => item.price * item.quantity + total, 0);
    },

    calcServiceFee() {
      // Healthcare Enroll Service Fee = 4% of total transaction fee.
      // Minimum service fee is $2.99

      const serviceFee = (this.cartArr.length !== 0 ? this.totalAmount : 0) * 0.04;

      return serviceFee > STD_REG_MIN_SERVICE_FEE ? serviceFee : STD_REG_MIN_SERVICE_FEE;
    },

    calTotalFee() {
      return (this.cartArr.length !== 0 ? this.totalAmount : 0) + this.calcServiceFee;
    },
  },
  methods: {
    get,
  },
  async mounted() {},
};
</script>
