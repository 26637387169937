<template>
  <div class="page-section">
    <b-form class="container my-4" @submit.prevent="onSubmit">
      <h2 class="text-center mb-2">User Details</h2>
      <p class="text-center mb-5">
        Please make sure to fill out this form correctly, you will need to pick your order from school premisis once
        your order is confirmed.
      </p>
      <div class="row">
        <b-form-group label="First Name" label-for="firstname" label-class="form-label" class="col-md-6">
          <b-form-input
            id="firstname"
            :placeholder="$t('userMsgs.firstName')"
            v-model.trim="user.firstName"
            required
            :state="!$v.user.firstName.required && $v.user.firstName.$dirty ? false : null"
          />
          <b-form-invalid-feedback v-if="!$v.user.firstName.required">This field is required.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Last Name" label-for="lastname" label-class="form-label" class="col-md-6">
          <b-form-input
            id="lastname"
            :placeholder="$t('userMsgs.lastName')"
            v-model.trim="user.lastName"
            required
            :state="!$v.user.lastName.required && $v.user.lastName.$dirty ? false : null"
          />
          <b-form-invalid-feedback v-if="!$v.user.lastName.required">This field is required.</b-form-invalid-feedback>
        </b-form-group>
      </div>

      <b-form-group label="Email" label-for="email" label-class="form-label">
        <b-form-input id="email" placeholder="Enter email" v-model="user.email" type="email" required />
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.phone')" label-for="phone" label-class="form-label">
        <b-form-input
          id="phone"
          placeholder="(888) 689 - 1235"
          v-model="user.phone"
          v-mask="'(###) ### - ####'"
          required
        />
      </b-form-group>

      <!-- Address Fields -->
      <b-form-group :label="$t('schoolMsgs.address')" label-for="address" label-class="form-label">
        <b-form-input id="address" :placeholder="$t('schoolMsgs.address')" v-model="user.address" required />
      </b-form-group>

      <div class="row">
        <b-form-group :label="$t('schoolMsgs.zipcode')" label-for="zipcode" label-class="form-label" class="col-md-6">
          <b-form-input id="zipcode" :placeholder="$t('schoolMsgs.zipcode')" v-model="user.zipcode" required />
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
          <b-form-input id="city" :placeholder="$t('schoolMsgs.city')" v-model="user.city" required />
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group :label="$t('schoolMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
          <v-select
            id="state"
            class="form-control v-select-custom"
            label="text"
            v-model="user.state"
            :reduce="state => state.value"
            placeholder="Select State"
            :options="stateOptions"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!user.state" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.country')" label-for="country" label-class="form-label" class="col-md-6">
          <v-select
            id="country"
            class="form-control v-select-custom"
            label="text"
            disabled
            v-model="user.country"
            :reduce="country => country.value"
            :placeholder="$t('schoolMsgs.countryPlaceholder')"
            :options="countryOptions"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!user.country" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>
      </div>

      <b-btn variant="primary" style="min-width: 150px" type="submit" class="btn-normal float-right my-3">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Proceed to Payment</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { get, map } from 'lodash';
import Page from '@/components/Page.vue';
import { countries } from 'countries-list';
import { required } from 'vuelidate/lib/validators';

var UsaStates = require('usa-states').UsaStates;

export default {
  components: {
    vSelect,
  },
  extends: Page,

  data() {
    return {
      title: 'User Details',
      user: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        zipcode: '',
        address: '',
        city: '',
        state: '',
        country: 'United States',
      },
      countryOptions: [],
      stateOptions: [],
      isLoading: false,
    };
  },
  validations() {
    return {
      user: {
        firstName: { required },
        lastName: { required },
      },
    };
  },

  computed: {},

  methods: {
    get,

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        try {
          const data = {
            first_name: this.user.firstName,
            last_name: this.user.lastName,
            email: this.user.email,
            phone: this.user.phone,
            zipcode: this.user.zipcode,
            address: this.user.address,
            city: this.user.city,
            state: this.user.state,
            country: this.user.country,
          };

          this.$emit('user', data);
          this.makeToast({ variant: 'success', msg: this.$t('schoolMsgs.schoolAdded') });
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }

      this.isLoading = false;
    },
  },

  async mounted() {
    var usStates = new UsaStates();
    this.stateOptions = this.stateOptions.concat(
      map(usStates.states, state => ({
        value: state.name,
        text: state.name,
      }))
    );
    this.countryOptions = this.countryOptions.concat(
      map(countries, country => ({
        value: country.name,
        text: country.name,
      }))
    );
  },
};
</script>
