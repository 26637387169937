<template>
  <div class="container pb-5">
    <div class="backResult my-3 font-weight-bold">
      <a href="#" @click.prevent="$router.go(-1)" style="text-decoration: underline" class="mr-2">
        <span class="material-icons icon-22pt">navigate_before</span>
      </a>
    </div>
    <div class="row">
      <div class="col-md-8">
        <img :src="product.product_url ? product.product_url : DefaultAvatar" class="w-100 rounded-lg" />
      </div>
      <div class="col-md-4">
        <h2 class="mb-2">{{ product.product_title }}</h2>
        <strong style="font-size: 17px;">{{ $n(product.price, 'currency', 'en-US') }}</strong>

        <div v-if="get(product, 'sizes')" class="d-flex w-100 align-items-center my-3">
          <strong class="mr-3" style="font-size: 15px;">
            Size
          </strong>
          <div class="custom-select-icon w-100" style="min-width: 50px">
            <b-form-select class="custom-select-icon__select" v-model="size" :options="sizeOptions"> ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-between my-4">
          <div class="d-flex align-items-center">
            <a class="clickable-item" @click.prevent="decrementQuantity(product)">
              <i style="font-size: 20px;" :style="quantity === 1 ? 'color: lightgray' : ''" class="fas fa-minus"></i>
            </a>
            <div class="mx-3 d-flex align-items-center justify-content-center" style="font-size: 20px; width: 15px;">
              {{ $n(quantity) }}
            </div>
            <a class="clickable-item" @click.prevent="incrementQuantity(product)">
              <i style="font-size: 20px;" class="fas fa-plus"></i>
            </a>
          </div>

          <b-btn variant="primary" style="height: 40px; min-width: 240px;" @click.prevent="addCart">
            <span>Add To Cart</span>
          </b-btn>
        </div>

        <div class="mt-4">
          <h4 class="mb-2" style="text-transform: capitalize">Description</h4>
          <p>{{ product.product_description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapActions } from 'vuex';
import Page from '@/components/Page.vue';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';

export default {
  components: {},
  extends: Page,
  data() {
    return {
      title: '',
      completeHeadTitle: '',
      DefaultAvatar,
      quantity: 1,
      size: null,
      product: {},
      sizeOptions: [{ value: null, text: 'Please Select', disabled: true }],
      isLoading: false,
    };
  },
  computed: {
    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },

  watch: {
    product: {
      immediate: true,
      deep: true,
      handler(value) {
        if (get(value, 'id')) {
          this.completeHeadTitle = `${get(this.product, 'product_title')}`;
        }
      },
    },
  },

  methods: {
    get,
    ...mapActions('student', ['getPublicProduct']),
    ...mapActions('settings', ['setSettings']),
    ...mapActions('productsStore', ['addToCart']),

    addCart() {
      this.makeToast({ variant: 'success', msg: 'Product added successfully.' });
      this.addToCart({ product: this.product, quantity: this.quantity, size: this.size });
      this.quantity = 1;
    },

    incrementQuantity() {
      this.quantity += 1;
    },

    decrementQuantity() {
      if (this.quantity > 1) {
        this.quantity -= 1;
      }
    },

    async fetchProduct() {
      this.isLoading = true;

      const response = await this.getPublicProduct({
        slug: this.$route.params.slug,
      });

      this.product = response.data;
      this.sizeOptions = this.sizeOptions.concat(this.product.sizes);

      this.isLoading = false;
    },
  },

  async mounted() {
    if (this.$route.params.slug) {
      this.fetchProduct();
    }
    this.setSettings({ layout: { layout: 'fixed' } });
  },
};
</script>

<style></style>
